<template>
    <div id="app">
        <div style="height: 100%;" class="main-frame">
            <el-container style="height: 100%; width: 100%;">
                <el-aside :width="isCollapse?'64px':'210px'" style="background-color:#262B4A;text-align: left;">
                    <el-row style="background:#262B4A;height:88px;color:#FFFFFF;overflow: hidden">
                        <el-col :span="24" style="text-align: left; padding-left: 20px; height: 87px; line-height: 58px;">
                            <i class="iconfont icon-shujujianguan f-left" style="font-size: 32px; margin-right: 10px; line-height: 87px;" v-show="isCollapse"></i>
                            <h3 class="f-left" style="font-weight: 500; font-size: 18px;">德莘云</h3>
                            <div class="f-clear"></div>
                        </el-col>
                        <el-col>
                            <div class="line-col"></div>
                        </el-col>
                    </el-row>
                    <div style="height:calc(100% - 88px); padding-top: 10px;">
                        <el-scrollbar wrap-class="scrollbar-wrapper">
                            <el-menu :collapse="isCollapse"
                                    style="border:none;"
                                    class="el-menu-vertical-demo"
                                    background-color="#262B4A"
                                    text-color="#fff"
                                    active-text-color="#FFFFFF"
                                    :collapse-transition="false"
                                    mode="vertical"
                                    router>
                                <el-submenu v-for="(menu, index) in menuData" :key="index" :index="index+''" v-if="menu.children && menu.children.length > 0">
                                    <template slot="title">
                                        <i class="iconfont mr-0_4 icon-menu-font" :class="menu.meta.icon"></i>
                                        <span>{{menu.meta.title}}</span>
                                    </template>
                                    <el-menu-item
                                            v-for="(item, i) in menu.children"
                                            :key="index+'-'+i"
                                            :index="item.path"
                                            @click="clickMenu(item)">
                                        <i class="iconfont mr-0_4 icon-menu-font" :class="item.icon"></i>
                                        <span>{{item.meta.title}}</span>
                                    </el-menu-item>
                                </el-submenu>
                                <el-menu-item :index="menu.path" v-else @click="clickMenu(menu)">
                                    <i class="iconfont mr-0_4 icon-menu-font" :class="menu.meta.icon"></i>
                                    <span slot="title">{{menu.meta.title}}</span>
                                </el-menu-item>
                            </el-menu>
                        </el-scrollbar>
                    </div>
                </el-aside>
                <el-container>
                    <el-row style="height: 48px; overflow: hidden; min-height: 48px;">
                        <el-col :span="12" class="dheader">
                             <div class="title">
                                <span>今天是{{weather.reporttime}}</span>
                                <span>{{weather.city}}</span>
                                <span>天气：{{weather.weather}}</span>
                                <span>温度：{{weather.temperature}}°C</span>
                                <span>湿度：{{weather.humidity}}</span>
                                <span>风力级别：{{weather.windpower}}</span>
                                <span>风向：{{weather.winddirection}}</span>
                            </div>
                        </el-col>
                        <el-col :span="12" style="line-height: 48px;text-align: right; padding-right: 15px; color: #000000;" class="head-top">
                            <el-input
                                v-model="keyword">
                                <i slot="suffix" class="el-input__icon el-icon-search" style="color: #000000;"></i>
                            </el-input>
                            <el-dropdown @command="profileCommand">
                                <button type="button" class="el-button el-dropdown-selfdefine drop-btn">
                                    <span>
                                        <span style="width: 32px; height: 32px; border-radius: 16px; background-size: cover; display: block; position: absolute; top: 8px; left: 0px;" :style="{backgroundImage: 'url(' + headImage + ')'}" class="span-head-image"></span>{{loginUserName}}
                                        <span class="span-role">({{roleName}})</span>
                                        <i class="el-icon-arrow-down el-icon--right" style="color: #969696; margin-left: 10px; font-size:12px;"></i>
                                    </span>
                                </button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="resetPwd"><i class="iconfont icon-xiugaimima"></i> 修改密码</el-dropdown-item>
                                    <el-dropdown-item command="exit"><i class="iconfont icon-tuichu"></i> 退出</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-col>
                    </el-row>
                    <el-header style="text-align: left;padding:0px;height: auto;" v-show="showTag">
                        <el-row>
                            <el-col :span="24">
                                <AppTag @showVisableTags="showVisableTags"/>
                            </el-col>
                        </el-row>
                    </el-header>
                    <el-main style="padding:0px 15px 20px 15px; background: #E5E5E5;">
                        <keep-alive>
                            <router-view v-if="$route.meta.keepAlive"></router-view>
                        </keep-alive>
                        <router-view v-if="!$route.meta.keepAlive"></router-view>
                    </el-main>
                </el-container>
            </el-container>
        </div>
        <el-dialog title="修改密码" :visible.sync="dialogVisible" :center="true" width="40%">
            <el-form :model="formData" ref="formData">
            <el-form-item label="原密码" label-width="80px" :rules="[{ required: true, message: '原密码不能为空'}]" prop="old_password">
                <el-input v-model="formData.old_password" autocomplete="off" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" label-width="80px" :rules="[{ required: true, message: '新密码不能为空'}]" prop="new_password1">
                <el-input v-model="formData.new_password1" autocomplete="off" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" label-width="80px" :rules="[{ required: true, message: '确认密码不能为空'}]" prop="new_password2">
                <el-input v-model="formData.new_password2" autocomplete="off" type="password"></el-input>
            </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitPwd" icon="el-icon-check">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import AppTag from "@/components/AppTag";
    import $ from "jquery";

    export default {
        name: 'Base',
        components: { AppTag },
        data() {
            return {
                isCollapse: false,
                menuData: [],
                weather:{
                    adcode: "",
                    city: "",
                    humidity: "",
                    province: "",
                    reporttime: "",
                    temperature: "",
                    weather: "",
                    winddirection: "",
                    windpower:'',
                },
                loginUserName: "",
                dialogVisible: false,
                formData: {old_password: '', new_password1: '', new_password2: ''},
                roleName: "管理员",
                headImage: "https://ss0.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=517488255,1628395133&fm=193&gp=0.jpg",
                keyword: "",
                showTag: true
            }
        },
        methods: {
            getMenuList() {
                var menus = []
                var all_routes = this.$router.options.routes
                console.log('all_routes',all_routes)
                all_routes.forEach(item => {
                    let is_super_master = this.utils.getSuperMaster();
                    if (item.meta.show) {
                        if (item.children) {
                            let menu = []
                            let iCount = 0;
                            item.children.forEach(ii => {
                                if (ii.meta.is_permission_check == true && is_super_master == 0) {
                                    if (this.check_show_menu(ii.meta.model)) {
                                        if (ii.meta.show) menu.push(ii)
                                        iCount ++;
                                    }
                                } else {
                                    if (ii.meta.show) menu.push(ii)
                                    iCount ++;
                                }
                            })
                            // 有子菜单权限
                            if (iCount > 0) {
                                menus.push({meta: item.meta, icon: item.meta.icon, path: item.redirect ? item.redirect : item.path, children: menu})
                            }
                        }
                    }
                })
                return menus;
            },
            toggleCollapse() {
                this.isCollapse = !this.isCollapse
            },
            clickMenu(value) {
               if (value.meta.title == "首页") {
                   this.showTag = false
               } else {
                   this.showTag = true
               }
               this.$store.commit('mutationSelectTags', {name: value.meta.title, path: value.path, icon: value.icon })
            },
            check_show_menu(model) {
                var b = false
                const permissions = this.utils.getPermission()
                console.log('permissions',permissions)
                for (var i = 0; i < permissions.length; i++) {
                    const vv = permissions[i]
                    if (vv.permission && vv.permission.model === model && vv.menu_show === 1 && vv.status === 1) {
                        b = true
                        break
                    }
                }
                return b
            },
            profileCommand(value) {
                if (value == 'exit') {
                    this.$confirm('退出登录, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.utils.clear();
                        this.$store.commit('muationClear',[])
                        this.$router.push('/login')
                    }).catch(() => {
                    });
                } else if (value == 'resetPwd') {
                    this.dialogVisible = true
                }
            },
            submitPwd() {
                this.$refs.formData.validate(valid => {
                    if (valid) {
                        if (this.formData.new_password1 != this.formData.new_password2) {
                            this.utils.errorToast("新密码和确认密码不一致，请检查");
                            return false;
                        }
                        this.http.put("ChangePassword", this.formData).then(resp => {
                            this.loading = false
                            if (resp.code == 200) {
                                this.utils.successToast(resp.msg)
                                this.dialogVisible = false
                                this.formData = {old_password: '', new_password1: '', new_password2: ''}
                            }
                        }).catch(err => {
                        })
                    }
                });
            },
            formatDate(dateStr) {
                let dateArr = dateStr.split(" ")
                let ymdArr = dateArr[0].split("-")
                return ymdArr[0] + "年" + ymdArr[1] + "月" + ymdArr[2] + "日"
            },
            getWeather() {
                var _this=this;
                $.ajax({
                    method: "GET",
                    url: "https://restapi.amap.com/v3/weather/weatherInfo?city=510100&key=221c12e500930c7085c3ac5601e05f5b",
                    headers: {
                        Accept: "application/json; charset=utf-8",
                    },
                    success: function (result, status) {
                        _this.weather = result.lives[0]
                        _this.weather.reporttime = _this.formatDate(_this.weather.reporttime)
                    },
                });
            },
            showVisableTags(isShow) {
                this.showTag = isShow
            }
        },
        created() {
            if (this.utils.getToken() == undefined) {
                this.utils.clear()
                this.$store.commit('muationClear',[])
                this.$router.push({name: 'login', params: {row: ''}})
            }
            this.menuData = this.getMenuList()
            this.getWeather()
            this.loginUserName = this.utils.getLoginUserName()

            //在页面加载时读取sessionStorage里的状态信息
            if (sessionStorage.getItem('storeState')) {
                this.$store.replaceState(Object.assign({}, this.$store.state, this.qs.parse(sessionStorage.getItem('storeState'))))
            }
            // //在页面刷新时将vuex里的信息保存到sessionStorage里
            window.addEventListener('beforeunload', () => {
                sessionStorage.setItem('storeState', this.qs.stringify(this.$store.state))
            })
            console.log(this.$router.currentRoute);

            //初始一个都没有的时候默认一下，默认设置首頁
            if (this.$store.state.stateTagsList.length == 0) {
                this.showTag = false
                this.$store.commit('mutationSelectTags', {name: '首页', path: '/welcome', icon: 'icon-shouye1', isNotClose: true})
            } else if (this.$store.state.stateTagsList.length >= 1) {
                this.showTag = true
                let tags = this.$store.state.stateTagsList
                for (let i = 0; i < tags.length; i ++) {
                    if ("首页" == this.$router.currentRoute. meta.title) {
                        this.showTag = false
                        break
                    }
                }
            }
        }
    }
</script>
<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #FFFFFF;
    }
    .line-col {
        margin-left: 10px;
        width: 180px;
        display: block;
        border-bottom: 1px solid;
        border-image: -webkit-linear-gradient(-270deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.00) 99%) 2 2 2 2;
        border-image: -moz-linear-gradient(-270deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.00) 99%) 2 2 2 2;
        border-image: linear-gradient(-270deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0.00) 99%) 2 2 2 2;
    }
    .el-menu-item.is-active, .el-menu-item:hover {
        background: #05060F !important;
        color: #ffffff !important;
    }
    .main-frame .el-menu-item, .main-frame .el-submenu__title {
        height:49px;
        line-height: 49px;
        margin: 5px 0;
    }
    .el-menu-item i.icon-menu-font, .el-submenu__title i.icon-menu-font {
        border-radius: 10px;
        background: #05060F;
        padding: 6px;
        font-size: 10px;
        color: #0075FF;
        font-weight: 500;
    }
    ::-webkit-scrollbar {
        width: 0px;
    }
    ::-webkit-scrollbar-track {
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
        border-radius:10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0,0,0,0.1);
        -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background:rgba(255,0,0,0.4);
    }
    .main-frame .mr-0_4 {
        margin-right: 0.5rem !important;
    }
    .main-frame .el-menu-item.is-active i.icon-menu-font, .main-frame .el-menu-item:hover i.icon-menu-font {
        color: #fff;
        background: #0075FF;
    }
    .main-frame .el-submenu__title:hover i.icon-menu-font {
        color: #fff;
        background: #0075FF;
    }
    .dheader .title {
        text-align: left;
        font-size: 12px;
        padding: 15px 1% 0 15px;
        color: #5E5E5E;
        line-height: 18px;
    }
    .dheader .title span {
        margin-left: 10px;
    }
    .el-dropdown .drop-btn, .el-dropdown .drop-btn:hover, .el-dropdown .drop-btn:focus {
       border: none;
       background-color: inherit;
       color: #000;
       padding: 0px 0px 0px 42px;
    }
    .span-role {
        color: #969696;
        font-size: 10px;
    }
    .f-left {
        float: left;
    }
    .f-clear {
        clear: both;
    }
    .main-frame .head-top .el-input {
        width: 180px;
        margin-right: 28px;
    }
    .main-frame .head-top .el-input__inner, .main-frame .head-top .el-input__inner:hover, .main-frame .head-top .el-input__inner:focus {
        height: 28px;
        width: 180px;
        background: #E5E5E5;
        color: #000;
        border: 0;
        border-radius: 8px;
    }
    .main-frame .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
        width: 100%;
    }
    .vmain {
        background: #ffffff;
        padding: 20px 20px 10px 30px;
    }
    h3.h3-child-title {
        font-size: 15px;
        line-height: 20px;
        text-align: left;
        color: #000;
        font-weight: 600;
        padding: 0;
        margin: 10px 0;
    }
    .vmain .search-header {
        background: none;
        border: 0;
        padding: 0 !important;
        min-height: 50px;
        margin: 0;
        margin-top: 10px;
    }
    .vmain .content-r-main {
        padding: 0 !important;
    }
    .vmain .content-aside {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
    }
</style>
