import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/apps.css'
import service from "@/utils/request";
import qs from 'qs'
import utils from "@/utils/utils";
import http from "@/utils/http";
import {MessageBox} from 'element-ui'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/css/quill.scss'
import Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize',ImageResize)
Vue.use(VueQuillEditor)

Vue.config.productionTip = false

// Vue.prototype.service = service;
Vue.prototype.qs = qs;
Vue.prototype.http=http
Vue.prototype.MessageBox=MessageBox
//
Vue.prototype.utils= utils;

window.root = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
