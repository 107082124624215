// AppTag组件
<template>
    <div class="app-tag">
        <el-scrollbar wrap-class="scrollbar-wrapper" :vertical="false">
            <el-tag
                :closable="tag.path=='/welcome'?false:true"
                size="medium"
                v-for="(tag,index) in tags"
                :key="index"
                :disable-transitions="true"
                :effect="$route.path === tag.path ?'dark':'plain'"
                @close="handleClose(tag,index)"
                @click="handleClick(tag)"
                @contextmenu.prevent.native="openRightMenu($event,tag)">
                {{tag.name}}
            </el-tag>
        </el-scrollbar>
        <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
            <li @click="sub_right_click(0)">刷新</li>
            <li v-show="isShowClose" @click="sub_right_click(1)">关闭</li>
        </ul>
    </div>
</template>
<script>
    import {mapState, mapMutations} from 'vuex';
    export default {
        name: 'app-tag',
        data() {
            return {
                tags: [],
                visible: false,
                top: 0,
                left: 0,
                currentTagItem: undefined,
                isShowClose: true
            }
        },
        created() {
            this.tags = this.stateTagsList;
        },

        computed: {
            ...mapState(['stateTagsList'])
        },

        methods: {
            ...mapMutations({
                close: 'mutationCloseTag'
            }),
            openRightMenu(e, item) {
                this.currentTagItem = item
                const menuMinWidth = 105
                const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
                const offsetWidth = this.$el.offsetWidth // container width
                const maxLeft = offsetWidth - menuMinWidth // left boundary
                const left = e.clientX - offsetLeft // 15: margin right

                if (left > maxLeft) {
                    this.left = maxLeft
                } else {
                    this.left = left
                }
                this.left = this.left + 20
                this.top = e.clientY - 100 // fix 位置bug
                this.visible = true

                if (item.isNotClose) {
                   this.isShowClose = false
                } else {
                   this.isShowClose = true
                }
            },
            closeMenu() {
                this.visible = false
            },
            sub_right_click(index) {
              switch (index) {
                case 0:
                  this.$router.go(0)
                  break
                case 1: {
                  if (this.currentTagItem) {
                    var tag = {}
                    var index = -1
                    for (var i = 0; i < this.tags.length; i++) {
                      if (this.tags[i].path == this.currentTagItem.path) {
                        tag = this.tags[i];
                        index = i;
                        break
                      }
                    }
                    if (index != -1) {
                      this.handleClose(tag, index)
                    }
                  }
                  break
                }
              }
            },
            handleClose(tag, index) {
                // 如果只有一个标签则不能关闭
                if (this.tags.length === 1) { 
                    return
                }
                this.close(tag) // 删除当前tag

                // 删除标签后则判断
                if (this.tags.length === 1) { 
                    this.$router.push(this.tags[0].path)
                    this.$emit("showVisableTags", false)
                    return
                }

                // 如果关闭的标签不是当前路由的话，不做路由跳转
                if (this.$router.currentRoute.path !== tag.path) { 
                    return
                } else {
                    if (index === (this.tags.length - 1)) {
                        if (index > 1) {
                            this.$router.push(this.tags[index - 1].path)
                        } else {
                            this.$router.push(this.tags[0].path)
                        }
                    } else { 
                        if (index === 0) {
                            this.$router.push(this.tags[0].path)
                        } else {
                            this.$router.push(this.tags[index - 1].path)
                        }
                    }
                }
            },
            // 点击tags具体标签
            handleClick(tag) {
                this.$router.push(tag.path)
                if (tag.name == "首页") {
                    this.$emit("showVisableTags", false)
                } else {
                    this.$emit("showVisableTags", true)
                }
            }
        },
        activated() {
            this.$router.push(this.path).catch(err => {
            })
        },
        watch: {
            visible(value) {
                if (value) {
                    document.body.addEventListener('click', this.closeMenu)
                } else {
                    document.body.removeEventListener('click', this.closeMenu)
                }
            }
        },
    }
</script>

<style>
    .app-tag {
        background: #E5E5E5;
        padding: 0 0 0 0;
        padding-left: 15px;
    }
    .app-tag .el-tag {
        cursor: pointer;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: none;
        margin-right: 5px;
        background: #DEDEDE;
        color: #1C232B;
        font-size: 14px;
        line-height: 20px;
        padding: 7px 22px;
        height: auto;
        margin-top: 3px;
    }
    .el-tag .el-tag__close {
        color: #1C232B !important;
    }
    .el-tag .el-tag__close:hover {
        color: #fff !important;
    }
    .app-tag .el-tag--dark {
        background: #ffffff;
        color: #409EFF
    }
    .el-tag--dark .el-tag__close {
        color: #409EFF !important;
    }
    .el-tag--dark .el-tag__close:hover {
        color: #fff !important;
    }
    .el-scrollbar__wrap {
        overflow-x: auto;
        height: calc(100% + 20px);
    }
    .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
        white-space: nowrap;
        display: inline-block;
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    }
    .contextmenu li {
        margin: 0;
        padding: 7px 16px;
        cursor: pointer;

    }
    .contextmenu li:hover {
        background: #eee;
    }
</style>
