import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'

const service = axios.create({
    // baseURL: 'http://127.0.0.1:8002/api/v1/patrol/', // localhost
    // baseURL: 'https://api.ds0707.com/api/v1/patrol/', //
    baseURL: 'https://api.ds0707.com/api/v1/patrol/', //
    timeout: 60 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
        // config.headers = {'Content-Type': 'application/x-www-form-urlencoded' }//配置请求头
        config.headers = {'Content-Type': 'application/json;charset=UTF-8'}//配置请求头
        // do something before request is sent
        if (localStorage.getItem('token')) {//store.getters.token
            config.headers['token'] = localStorage.getItem('token')
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.code === 1001) {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
        }
        if (res.code === 1005) {
            Message({
                message: '该删除记录有关联外键记录，不允许删除，请先删除关联记录，再删除该数据',
                type: 'error',
                duration: 5 * 1000
            })
        }
        if (res.code === 403) {
            Message({
                message: '没有权限，请联系管理员开通/登录过期，请重新登录',
                type: 'error',
                duration: 5 * 1000
            })
        }
        if (res.code === 401) {
            localStorage.clear()
            sessionStorage.clear()
            router.replace({
                path: '/login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            });
        }
        if (res.code === 404) {
            Message({
                message: '网络请求不存在',
                type: 'error',
                duration: 5 * 1000
            })
        }
        return res
    },
    error => {
        if (error.response.status === 403) {
            Message({
                message: '您没有该功能的操作权限',
                type: 'error',
                duration: 5 * 1000
            })
        } else {
            console.log('error' + error) // for debug
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)
    }
)

export default service
