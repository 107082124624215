import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from '../views/Base/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
        meta: {title: '首页', icon: 'el-icon-s-help', show: false, theme: '',},
    },
    //把所有路由都配置在这里
    /** meta说明
     * shared//是不是共用页面
     * meta: {
     * title: '部门管理',
     * model: 'Department',//模型名称，后台传过来要进行权限匹配的值
     * show: true,//菜单列表是否显示
     * get: true,//list获取列表权限
     * post: true,//添加权限
     * put: true,//修改权限
     * delete: true,//删除权限
     * keepAlive: true,//保持页面，多标签切换时会保持原有的内容
     * is_permission_check: true//是否需要权限验证，有一些弹出的路由是不需要权限验证的直接设置成false即可 ,有二级菜单的主菜单也不需要验证权限
     * theme:属于那个菜单
     * },
     *
     * 以上权限验证前后端分离的，就算前端窜改了提交方法也不担心，后台也有权限验证功能的
     *
     * */
    {
        name: "Welcome",
        path: '/',
        redirect: '/welcome',
        component: Base,
        meta: {title: '首页', icon: 'icon-shouye1', show: true,  keepAlive: true},
        children: [
            {
                icon: 'icon-shouye1',
                path: '/welcome',
                component: () => import('@/views/Welcome/index'),
                name: 'Welcome',
                meta: {title: '首页', model: '', show: false, get: true, post: true, put: true, delete: true, is_permission_check: false, keepAlive: true},
            }
        ]
    },
    {
        name: "Users",
        path: '',
        component: Base,
        meta: {title: '用户管理', icon: 'icon-yonghuguanli', show: true},
        children: [
            {
                icon: 'icon-yonghuguanli1',
                name: "UsersList",
                path: '/Users/index',
                component: () => import('@/views/Users/index.vue'),
                meta: {title: '用户', model: 'Users', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-zuzhibumen',
                name: "DepartmentList",
                path: '/DepartmentList/index',
                component: () => import('@/views/Department/index.vue'),
                meta: {title: '部门', model: 'Department', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-yidongyunkongzhitaiicon45',
                name: "RoleList",
                path: '/RoleList/index',
                component: () => import('@/views/Role/index.vue'),
                meta: {title: '角色', model: 'Role', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-gongnengjiaosequanxianguanli',
                name: "RolePermissionList",
                path: '/RolePermissionList/index',
                component: () => import('@/views/RolePermission/index.vue'),
                meta: {title: '角色权限', model: 'RolePermission', show: false, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            }
        ]
    },
    {
        name: "Protal",
        path: '',
        component: Base,
        meta: {title: '巡检管理', icon: 'icon-xunjianguanli1', show: true},
        children: [
            {
                icon: 'icon-fengxianguankong',
                name: "RiskLevel",
                path: '/RiskLevel/index',
                component: () => import('@/views/RiskLevel/index.vue'),
                meta: {title: '风险等级', model: 'RiskLevel', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-andanganleixingtongji',
                name: "PatrolCategory",
                path: '/PatrolCategory/index',
                component: () => import('@/views/PatrolCategory/index.vue'),
                meta: {title: '日常巡检分类', model: 'PatrolCategory', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-gongju',
                name: "PatrolProject",
                path: '/PatrolProject/index',
                component: () => import('@/views/PatrolProject/index.vue'),
                meta: {title: '日常巡检', model: 'PatrolProject', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-andanganleixingtongji',
                name: "SpecialInspection",
                path: '/SpecialInspectionCategory/index',
                component: () => import('@/views/SpecialInspectionCategory/index.vue'),
                meta: {title: '专项巡检分类', model: 'SpecialInspectionCategory', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-gongju',
                name: "SpecialInspection",
                path: '/SpecialInspection/index',
                component: () => import('@/views/SpecialInspection/index.vue'),
                meta: {title: '专项巡检', model: 'SpecialInspection', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-weizhi',
                name: "PatrolLocationCategory",
                path: '/PatrolLocationCategory/index',
                component: () => import('@/views/PatrolLocationCategory/index.vue'),
                meta: {title: '巡检位置分级', model: 'PatrolLocationCategory', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-a-fasongdaohang-m',
                name: "PatrolLocation",
                path: '/PatrolLocation/index',
                component: () => import('@/views/PatrolLocation/index.vue'),
                meta: {title: '巡检位置', model: 'PatrolLocation', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-M_huojian',
                name: "DailyPlan",
                path: '/DailyPlan/index',
                component: () => import('@/views/DailyPlan/index.vue'),
                meta: {title: '巡检计划', model: 'DailyPlan', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-shijian-shi',
                name: "DailyTaskRecord",
                path: '/DailyTaskRecord/index',
                component: () => import('@/views/DailyTaskRecord/index.vue'),
                meta: {title: '巡检记录', model: 'DailyTaskRecord', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-fenlei1',
                name: "HiddenDangerCategory",
                path: '/HiddenDangerCategory/index',
                component: () => import('@/views/HiddenDangerCategory/index.vue'),
                meta: {title: '隐患分类', model: 'HiddenDangerCategory', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-bg-upload',
                name: "HiddenUpload",
                path: '/HiddenUpload/index',
                component: () => import('@/views/HiddenUpload/index.vue'),
                meta: {title: '隐患上报', model: 'HiddenUpload', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-gonggongziliao',
                name: "LearnMeta",
                path: '/LearnMeta/index',
                component: () => import('@/views/LearnMeta/index.vue'),
                meta: {title: '学习资料', model: 'LearnMeta', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            },
            {
                icon: 'icon-tongzhi',
                name: "SafeNotice",
                path: '/SafeNotice/index',
                component: () => import('@/views/SafeNotice/index.vue'),
                meta: {title: '安全通知', model: 'SafeNotice', show: true, get: true, post: true, put: true, delete: true, is_permission_check: true, keepAlive: true},
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router
