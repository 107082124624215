<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    import {setPermission, setToken} from "./utils/auth";
    export default {
        name: 'Apps',
        data() {
            return {}
        },
        created() {
        },
        methods:{
        },
    }
</script>
