import qs from 'qs'
import service from "@/utils/request";
import {MessageBox, Message} from 'element-ui'
import moment from 'moment';

const TokenKey = 'token'
const PermissionKey = 'permission'
const IsSuperMaster = 'is_super_master'
const UserGroup = 'user_group'
const UserGroupName = 'user_group_name'
const LoginUserName = "login_user_name"

// 设置缓存
const set = (key, value) => {
    // localStorage.setItem(key, qs.stringify(value));
    localStorage.setItem(key, JSON.stringify(value));
};
// 获取缓存
const get = (key) => {
    // return qs.parse(localStorage.getItem(key));
    return JSON.parse(localStorage.getItem(key));
};
// 清楚缓存
const remove = (key) => {
    localStorage.removeItem(key);
};
// 清空缓存
const clear = () => {
    localStorage.clear();
    sessionStorage.clear();
};


const getToken = () => {
    return localStorage.getItem(TokenKey)
};

const setToken = (token) => {
    // return Cookies.set(TokenKey, token)
    return localStorage.setItem(TokenKey, token)
}

const setPermission = (permission) => {
    return localStorage.setItem(PermissionKey, JSON.stringify(permission))//qs.stringify(permission))//{arrayFormat:'indices'}
}

const getPermission = () => {
    return JSON.parse(localStorage.getItem(PermissionKey));
}

const setLoginUserName = (loginUserName) => {
    return localStorage.setItem(LoginUserName, loginUserName)
}

const getLoginUserName = () => {
    return localStorage.getItem(LoginUserName)
}

const getSuperMaster = () => {
    return localStorage.getItem(IsSuperMaster)
}

const setSuperMaster = (is_super_master) => {
    return localStorage.setItem(IsSuperMaster, is_super_master)
}

const getUserGroup = () => {
    return localStorage.getItem(UserGroup)
}

const setUserGroup = (group_id) => {
    return localStorage.setItem(UserGroup, group_id)
}

const getUserGroupName = () => {
    return localStorage.getItem(UserGroupName)
}

const setUserGroupName = (group_name) => {
    return localStorage.setItem(UserGroupName, group_name)
}
//-------------------页面用到公共函数---------------------
const getTableHeight = (tableH) => {
    var tableHeight = 0
    //let tableH = 325; //距离页面下方的高度
    let tableHeightDetil = window.innerHeight - tableH;
    if (tableHeightDetil <= 300) {
        tableHeight = 300;
    } else {
        tableHeight = window.innerHeight - tableH;
    }
    return tableHeight
}
// 修改table header的背景色
const setTableHeaderColor = ({row, column, rowIndex, columnIndex}) => {
    if (rowIndex === 0) {
        return 'background-color: #C7E0FF;color: #000000;font-weight: 600;'
    }
    return ''
}
//-------------------toast---------------------
const successToast = (message) => {
    Message({
        message: message,
        type: 'success',
        duration: 5 * 1000
    })
}
const errorToast = (message) => {
    Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
    })
}
//-------------------功能函数---------------------
const clearDict = (data) => {
    for (var key in data) {
        data[key] = ''
    }
    return data
}
const checkOperationPrimission = () => {
    //原理，根据路由找到对应的model名称，在根据model去登录过后返回的权限列表进行匹配相对应的操作权限
    const currentPath = root.$route.path.toLowerCase()
    var all_routes = root.$router.options.routes
    var model = ''
    for (var i = 0; i < all_routes.length; i++) {
        const item = all_routes[i]
        if (item.children && item.children.length === 1) {
            if (item.children[0].path.toLowerCase() === currentPath) {
                model = item.children[0].meta ? item.children[0].meta.model : ''
                break
            }
        } else {
            if (item.children && item.children.length > 1) {
                for (var j = 0; j < item.children.length; j++) {
                    if (item.children[j].path.toLowerCase() === currentPath) {
                        model = item.children[j].meta ? item.children[0].meta.model : ''
                        break
                    }
                }
            }
            // 查找到模块数据
            if (model != "") {
                break;
            }
        }
    }
    if (model.length === 0)
        return [0, 0, 0]//post,put,delete

    // var b = false
    const permissions = getPermission()
    var ret = []
    for (var i = 0; i < permissions.length; i++) {
        const vv = permissions[i]
        if (vv.permission && vv.permission.model === model) {
            ret = [vv.method_post, vv.method_put, vv.method_delete]
            break
        }
    }
    return ret
}

const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data))
}

const leaveTime=(v)=>{
    return moment(v).format('YYYY-MM-DD HH:mm:ss');
}
const fmtDate=(v,fmt)=>{
    return moment(v).format(fmt);
}


export default {
    set,
    get,
    remove,
    clear,
    getToken,
    setToken,
    setPermission,
    getPermission,
    getSuperMaster,
    setSuperMaster,
    getUserGroup,
    setUserGroup,
    getUserGroupName,
    setUserGroupName,
    setLoginUserName,
    getLoginUserName,
    //-------------------页面用到公共函数---------------------
    getTableHeight,
    setTableHeaderColor,
    //-------------------toast---------------------
    successToast,
    errorToast,
    //-------------------功能函数---------------------
    clearDict,
    checkOperationPrimission,
    deepCopy,

    leaveTime,
    fmtDate,
};
