import Vue from 'vue'
import Vuex from 'vuex'
import {getToken, getPermission, getSuperMaster, getUserGroup, getUserGroupName} from "@/utils/auth";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        stateTagsList: [],//tagviews数组
    },
    getters: {},
    mutations: {
        /**
         * 选择tag标签
         */
        mutationSelectTags(state, data) {
            let result = false
            for (let i = 0; i < state.stateTagsList.length; i++) {
                if (state.stateTagsList[i].path == data.path) {
                    return result = true
                }
            }
            result === false ? state.stateTagsList.push(data) : ''
        },
        /**
         * 关闭tag标签
         */
        mutationCloseTag(state, data) {
            let result = state.stateTagsList.findIndex(item => item.path === data.path)
            state.stateTagsList.splice(result, 1)
        },
        muationClear(state,data) {
            state.stateTagsList = data
        },
    },
    actions: {},
    modules: {}
})


